<template>
  <div>
    <b-card no-body>
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              :label="$t('labels.customer_name')"
              label-for="customer_name"
            >
              <b-form-input
                id="customer_name"
                :value="searchFields.customer_name"
                @input="inputChange($event, 'customer_name')"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              :label="$t('labels.contact_number')"
              label-for="contact_number"
            >
              <b-form-input
                id="contact_number"
                :value="searchFields.contact_number"
                @input="inputChange($event, 'contact_number')"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <TableIndex
      :resource-name="resourceName"
      :singular-name="singularName"
      :filter-fields="searchFields"
      :have-dropdown-actions="true"
      :custom-update-object="custom_update_object"
    >
      <template v-slot:custom_actions="{ item }">
        <b-link
          v-if="(item.actions && item.actions.can_view)"
          v-b-tooltip.hover.v-primary
          :title="$t('details')"
          :to="{ name: 'sale-requests-details', params: { resourceId: item.id } }"
          class="ml-1"
        >

          <feather-icon
            icon="EyeIcon"
            size="16"
            class="align-middle text-body"
          />
        </b-link>
      </template>
      <template v-slot:dropdown_actions="{ item, generalActions }">
        <b-dropdown-item
          v-if="item.actions && item.actions.can_upload_field_evaluation_report"
          @click="showModal(item)"
        >
          <feather-icon
            icon="UploadIcon"
            class="mr-50"
          />
          <span>{{ $t('Upload field evaluation') }}</span>
        </b-dropdown-item>
        <b-dropdown-item
          v-if="item.actions && item.actions.can_generate_financial_offer"
          @click="generateFinancialOffer(item)"
        >
          <feather-icon
            icon="UploadIcon"
            class="mr-50"
          />
          <span>{{ $t('Upload financial offer') }}</span>
        </b-dropdown-item>
        <b-dropdown-item
          v-if="item.actions && item.actions.can_generate_sale_contract_file"
          @click="generateSaleContract(item)"
        >
          <feather-icon
            icon="UploadIcon"
            class="mr-50"
          />
          <span>{{ $t('generate-sale-contract') }}</span>
        </b-dropdown-item>
      </template>
    </TableIndex>
    <b-modal
      id="modal-upload-file"
      ref="modal-upload-file"
      cancel-variant="outline-secondary"
      :ok-title="$t('yes')"
      :cancel-title="$t('no')"
      centered
      :title="$t('Upload field evaluation')"
      hide-footer
      :cancel-disabled="false"
    >
      <validation-observer
        ref="form"
        v-slot="{invalid}"
      >
        <b-form
          novalidate
          @submit.prevent="uploadFile"
        >
          <validation-provider
            v-slot="{ errors }"
            vid="file_name"
            :name="$t('field evaluation')"
            rules="required"
          >
            <b-form-group>
              <label
                for="file-input"
                class="custom-uploader-input"
                @drop="handleDrop($event)"
                @dragover.prevent
                @dragenter.prevent
              >
                {{ file_name ? file_name : $t('Choose a file or drop it here...') }}
              </label>
              <b-form-file
                id="file-input"
                ref="file-input"
                v-model="file"
                :placeholder="$t('Choose a file or drop it here...')"
                :drop-placeholder="$t('Drop file here...')"
                no-drop
                accept=".pdf"
                class="d-none"
                @change="uploader($event)"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
          <div class="d-flex">
            <LoadingButton />
            <b-button
              v-if="file_name"
              variant="outline-danger"
              class="mr-1"
              @click="clearFiles"
            >
              {{ $t('delete file') }}
            </b-button>
            <b-button
              variant="outline-secondary"
              @click="hideModal"
            >
              {{ $t('close') }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import { BFormFile } from 'bootstrap-vue'
import listMixin from '@/mixins/listMixin'

export default {
  components: {
    BFormFile,
  },
  mixins: [listMixin],
  data() {
    return {
      resourceName: 'real-estate-sell-requests',
      singularName: 'real-estate-sell-request',
      roles: [],
      searchFields: {
        customer_name: null,
        contact_number: null,
      },
      item: null,
      file: [],
      file_name: null,
      custom_update_object: null,
    }
  },
  methods: {
    handleDrop(event) {
      event.preventDefault()
      const files = Array.from(event.dataTransfer.files)
      this.file = files
      this.uploader(files, 'handleDrop')
    },
    generateSaleContract(item) {
      this.$bvModal
        .msgBoxConfirm(this.$t('Please confirm that you want to upload sale contract'), {
          title: this.$t('Please Confirm'),
          size: 'sm',
          okVariant: 'primary',
          okTitle: this.$t('yes'),
          cancelTitle: this.$t('no'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.axios.get(`real-estate-sell-requests/${item.id}/generate-sale-contract`)
              .then(response => {
                this.custom_update_object = response.data.data
                setTimeout(() => {
                  this.$bvToast.toast(response.data.message, {
                    title: this.$t('Success'),
                    variant: 'success',
                    solid: true,
                    autoHideDelay: 5000,
                    appendToast: true,
                  }, 500)
                })
              }).catch(response => {
                this.$bvToast.toast(response.data.message, {
                  title: this.$t('Error'),
                  variant: 'error',
                  solid: true,
                  autoHideDelay: 5000,
                  appendToast: true,
                }, 500)
              })
          }
        })
    },
    generateFinancialOffer(item) {
      this.$bvModal
        .msgBoxConfirm(this.$t('Please confirm that you want to upload financial offer'), {
          title: this.$t('Please Confirm'),
          size: 'sm',
          okVariant: 'primary',
          okTitle: this.$t('yes'),
          cancelTitle: this.$t('no'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.axios.post(`real-estate-sell-requests/${item.id}/generate-financial-offer`)
              .then(response => {
                this.custom_update_object = response.data.data
                setTimeout(() => {
                  this.$bvToast.toast(response.data.message, {
                    title: this.$t('Success'),
                    variant: 'success',
                    solid: true,
                    autoHideDelay: 5000,
                    appendToast: true,
                  }, 500)
                })
              }).catch(response => {
                this.$bvToast.toast(response.data.message, {
                  title: this.$t('Error'),
                  variant: 'error',
                  solid: true,
                  autoHideDelay: 5000,
                  appendToast: true,
                }, 500)
              })
          }
        })
    },
    clearFiles() {
      this.$refs['file-input'].reset()
      this.$refs.form.reset()
      this.file_name = null
    },
    uploader(e, key) {
      if (key === 'handleDrop') {
        const files = e
        if (files.length > 0) {
          const formData = new FormData()
          formData.append('files[]', files[0])
          formData.append('type', 'array')
          this.axios.post('/file/uploader/action', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
            .then(response => {
              // eslint-disable-next-line prefer-destructuring
              this.file_name = response.data[0].name
            }).catch(() => {
              this.file_name = null
            })
        }
      } else {
        const { files } = e.target
        if (files.length > 0) {
          const formData = new FormData()
          formData.append('files[]', files[0])
          formData.append('type', 'array')
          this.axios.post('/file/uploader/action', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
            .then(response => {
              // eslint-disable-next-line prefer-destructuring
              this.file_name = response.data[0].name
            }).catch(() => {
              this.file_name = null
            })
        }
      }
    },
    uploadFile() {
      this.$refs.form.validate()
        .then(result => {
          if (result) {
            this.axios.post(`real-estate-sell-requests/${this.item.id}/upload-field-evaluation-report`, {
              field_evaluation_report_file: this.file_name,
            })
              .then(response => {
                this.custom_update_object = response.data.data
                setTimeout(() => {
                  this.$bvToast.toast(response.data.message, {
                    title: this.$t('Success'),
                    variant: 'success',
                    solid: true,
                    autoHideDelay: 5000,
                    appendToast: true,
                  }, 500)
                  this.hideModal()
                  this.$refs['file-input'].reset()
                  this.$refs.form.reset()
                  this.file_name = null
                })
              }).catch(response => {
                this.$bvToast.toast(response.data.message, {
                  title: this.$t('Error'),
                  variant: 'error',
                  solid: true,
                  autoHideDelay: 5000,
                  appendToast: true,
                }, 500)
              })
          }
        })
    },
    // eslint-disable-next-line func-names
    inputChange: _.debounce(function (e, field) {
      this.searchFields[field] = e
    }, 500),
    getRoles() {
      this.axios.get('/lists/role')
        .then(res => {
          this.roles = res.data.data
        })
    },
    showModal(item) {
      this.$refs['modal-upload-file'].show()
      this.item = item
    },
    hideModal() {
      this.$refs['modal-upload-file'].hide()
    },
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    statuses() {
      return [
        {
          id: 1,
          name: this.$t('statuses.active'),
        },
        {
          id: 0,
          name: this.$t('statuses.inactive'),
        },
      ]
    },
  },
  // eslint-disable-next-line vue/order-in-components
  created() {
    this.getRoles()
  },
}
</script>
<style>
.custom-uploader-input {
  border: 1px dashed #ddd;
  width: 100%;
  display: block;
  padding: 8px;
  border-radius: 4px;
  margin: 0;
}
</style>
